
export default function Logo() {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1650 / 15}
      height={585.582 / 15}
      colorInterpolationFilters="sRGB"
      viewBox="122 90.85 174 68.3"
    >
      <g fill="#0a0619" className="icon-text-wrapper icon-svg-group iconsvg">
        <path
          d="M140.508 133.885v5.049h-18.234q-.702 0-1.503-.162-.81-.171-1.593-.522-.792-.342-1.512-.9-.711-.558-1.269-1.341-.558-.783-.882-1.827-.333-1.035-.333-2.34v-13.68q0-.702.162-1.512.171-.801.522-1.593.342-.783.909-1.503.567-.72 1.35-1.27.792-.557 1.818-.89 1.035-.324 2.331-.324h18.234v5.049h-18.234q-.99 0-1.512.522-.531.53-.531 1.557v13.644q0 .972.54 1.503.531.54 1.503.54h18.234Zm27.693-11.43v5.094h-15.957v-5.094h15.957Zm2.268 11.43v5.049h-18.225q-1.053 0-2.295-.37-1.242-.368-2.304-1.214-1.062-.846-1.764-2.196-.711-1.35-.711-3.312v-18.234q0-.522.189-.99.198-.468.54-.81.342-.351.801-.55.468-.188 1.017-.188h22.752v5.049h-20.25v15.723q0 .99.531 1.512.522.53 1.53.53h18.234Zm30.069-22.815v5.049h-11.142v22.815h-5.049v-22.815h-11.151v-5.05h27.342Zm8.802 0v27.864h-5.058V111.07h5.058Zm28.818 11.385v5.094h-15.957v-5.094h15.957Zm2.277 11.43v5.049h-18.234q-1.044 0-2.286-.37-1.251-.368-2.304-1.214-1.062-.846-1.773-2.196-.711-1.35-.711-3.312v-18.234q0-.522.198-.99.198-.468.531-.81.342-.351.81-.55.468-.188 1.008-.188h22.761v5.049h-20.25v15.723q0 .99.522 1.512.522.53 1.539.53h18.189Zm32.724-22.815v25.704q0 .549-.207 1.017-.198.459-.54.8-.342.343-.81.54-.468.19-.99.19-.468 0-.945-.171-.468-.18-.837-.567l-18.468-19.278v19.629h-5.049V113.22q0-.774.432-1.404.441-.63 1.125-.945.711-.288 1.476-.144.756.144 1.296.71l18.468 19.252v-19.62h5.049Zm31.059 0v5.049h-11.142v22.815h-5.049v-22.815h-11.16v-5.05h27.351Z"
          className="tp-name iconsvg-namesvg dark:fill-standard-text"
        />
      </g>
    </svg>

  );
}
