import * as HeroIcons from "@heroicons/react/24/outline";
import { HeroIconName } from "@/lib/ui/types";

interface IconProps {
  icon: HeroIconName;
  className?: string;
}

// Borrowed from https://github.com/tailwindlabs/heroicons/issues/278
export const DynamicHeroIcon = ({ icon, className }: IconProps) => {
  const SingleIcon = HeroIcons[icon];

  return (
    <SingleIcon className={className} />
  );
};
