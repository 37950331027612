'use client';

import { Navigation } from '@/lib/ui/types';
import { classNames, updateCurrentNavigationItem } from '@/lib/ui/utils';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { DynamicHeroIcon } from './dynamic-hero-icon';


interface SidebarNavigationProps {
  navigation: Navigation;
  setNavigation: React.Dispatch<React.SetStateAction<Navigation>>;
}

export default function SidebarNavigation({ navigation, setNavigation }: SidebarNavigationProps) {

  const pathname = usePathname();

  useEffect(() => {
    setNavigation((navigation: Navigation) => updateCurrentNavigationItem(navigation, pathname));
  }, [pathname, setNavigation]);

  return (
    <nav className="flex flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        {navigation.map((section) => (
          <li key={section.key || section.name}>
            { section.name !== '' && (
              <div className="sticky bg-sidebar-background -mx-5 px-5 top-0 text-xs font-semibold leading-6 mb-2 text-nowrap">{section.name}</div>
            )}
            <ul role="list" className="-mx-5">
              {section.items.map((item) => (
                <li key={item.href}
                  className={classNames(
                    'flex items-center',
                    item.disabled ?
                      'text-disabled-text hover:text-disabled-text-hover'
                      : item.current ?
                        'bg-list-item-current'
                        : 'hover:bg-list-item-hover',
                    section.name == '' ? 'font-semibold' : '',
                    'gap-x-1 rounded-md text-sm leading-6 group',
                  )}
                >
                  <Link
                    className="flex flex-1 items-center gap-x-2 min-w-0 truncate py-2 pl-5"
                    href={item.disabled ? '#' : item.href}
                    prefetch={false}
                  >
                    {item.icon && (
                      <DynamicHeroIcon icon={item.icon}
                        className={classNames(
                          item.disabled || item.current ? '' : 'text-tertiary-text group-hover:text-secondary-text',
                          'h-6 w-6 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                    )}
                    {item.initial && (
                      <span
                        className={classNames(
                          item.disabled
                            ? 'border-disabled-text group-hover:border-disabled-text-hover'
                          :item.current
                            ? 'border-standard-text'
                            : 'text-tertiary-text border-tertiary-text group-hover:text-secondary-text group-hover:border-secondary-text',
                          'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border text-[0.625rem] font-medium'
                        )}
                      >
                        {item.initial}
                      </span>
                    )}

                    <span className="truncate">{item.name}</span>

                    {item.count ? (
                      <span
                        className={classNames(
                          item.disabled
                            ? 'ring-disabled-text group-hover:ring-disabled-text-hover'
                          :item.current
                            ? 'ring-standard-text'
                            : 'ring-tertiary-text group-hover:ring-secondary-text',
                          "ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-xs font-medium leading-5 ring-1 ring-inset"
                        )}
                        aria-hidden="true"
                      >
                        {item.count}
                      </span>
                    ) : null}
                  </Link>

                  {item.current && item.menu && (
                    <Menu>
                      <MenuButton className={classNames(
                        "inline-flex items-center p-1 mr-1",
                        "focus:outline-none",
                        item.current ? "sm:hover:bg-list-item-hover" : "sm:hover:bg-list-item-current",
                        "sm:hover:rounded-md",
                        "max-sm:active:bg-list-item-hover max-sm:active:rounded-md",
                      )}>
                        <svg className="h-5 w-5 text-secondary-text" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 6a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 6a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" />
                        </svg>
                      </MenuButton>
                      <MenuItems
                        anchor="bottom start"
                        transition
                        className="rounded-md bg-input-background py-2 shadow-lg ring-1 ring-standard-ring focus:outline-none min-w-[var(--button-width)] origin-top-right transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 z-40"
                      >
                        {item.menu.map((menuItem, idx) => (
                          <MenuItem key={idx}>
                            {({ focus }) => (
                              <button
                                className={`${focus ? 'bg-list-item-hover' : ''} block w-full px-4 py-2 text-left text-sm text-standard-text`}
                                onClick={menuItem.onClick}>
                                {menuItem.label}
                              </button>
                            )}
                          </MenuItem>
                        ))}
                      </MenuItems>
                    </Menu>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );

}
